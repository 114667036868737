let propertyCode = "";
export const RATE_CODE = "INTERNET";

const propertyCodesList = [
  "grandcanyonlodges",
  "oasisatdeathvalley",
  "zionlodge",
  "yellowstonenationalparklodges",
  "glaciernationalparklodges",
  "grandcanyongrandhotel",
];

propertyCode = getPropertyCode();

function getPropertyCode() {
  let host = window.location.host;

  host = ("", host.split(".")).join("");

  for (const code of propertyCodesList) {
    //console.log("code", code);

    if (host.indexOf(code) !== -1) {
      return code;
    }
  }

  return propertyCodesList[0];
}

/*function getCodeFromDomain(str) {
  str = str.split('.')[0]
  const length = str.length
  if ((str.indexOf('apiuat') + 6) === length) {
    str = str.substr(0, str.indexOf('apiuat'))
  } else if ((str.indexOf('api') + 3) === length) {
    str = str.substr(0, str.indexOf('api'))
  }

  return str
}*/

export const PROPERTY_CODE = propertyCode;

export const SIZES = {
  sm: 374,
  md: 768,
  lg: 991,
  xl: 1198,
  xxl: 1599,
  xxxl: Infinity,
};

export const RESET_PASSWORD_TOKEN_LIFETIME = 1000 * 60 * 10; // 10 MIN

export const AVAILABILITY_STATUS_OPTIONS = [
  { value: 'available', text: 'Available' },
  { value: 'soldOut', text: 'Sold out' },
  { value: 'closed', text: 'Closed' },
  { value: 'notEnoughTime', text: 'Not available' },
  { value: 'notInRange', text: 'Not in range' },
  { value: 'childrenNotAllowed', text: 'Children not allowed' },
  { value: 'guestsExceededAvailability', text: 'Qty exceeded availability' }
]
